
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import { apiEndpoint } from "@/mixin/apiEndpoint";
import axios from 'axios';
import { ElNotification } from 'element-plus';
export default defineComponent({
  mixins: [apiEndpoint],
  name: "Trainne Attendance Status",
  components: {
    Datatable,
  },
  data() {
    return {
      componentKey: 0,
      load: false,
      id: this.$route.params.id as any,
      ispresent: this.$route.params.ispresent as any,
      datatable: false,
      tableHeader: [
        {
          name: "Sl",
          key: "sl",
          sortable: true,
          width: "5px",
        },
        {
          name: "Term",
          key: "term",
          sortable: true,
        },
        {
          name: "Session",
          key: "session",
          sortable: true,
          width: "170px",
        },
        {
          name: "Date",
          key: "date",
          sortable: true,
          width: "170px",
        },
        {
          name: "InTime",
          key: "intime",
          sortable: true,
        },
        {
          name: "OutTime",
          key: "outtime",
          sortable: true,
        },
      ],
      testData: [],
      trainee_info: '',
      tableData: [] as any,
    };
  },
  async created() {
    this.getAttendanceStatus();

    //this.testData = this.attendanceData;
  },
  methods: {
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();
      if (this.ispresent == "present") {
        this.ispresent = 1;
      }
      if (this.ispresent == "absent") {
        this.ispresent = 0;
      }

      formData.set('traineeId', this.id);
      formData.set('isPresent', this.ispresent);

      axios
        .post(
          `${this.VUE_APP_API_URL}/api/trainee/attendance-statusexport`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Attendance Status.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();
      if (this.ispresent == "present") {
        this.ispresent = 1;
      }
      if (this.ispresent == "absent") {
        this.ispresent = 0;
      }
      formData.set('traineeId', this.id);
      formData.set('isPresent', this.ispresent);

      // console.log(JSON.stringify(this.st));

      await ApiService.post('trainee/attendance-statuspdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async printPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();
      if (this.ispresent == "present") {
        this.ispresent = 1;
      }
      if (this.ispresent == "absent") {
        this.ispresent = 0;
      }
      formData.set('traineeId', this.id);
      formData.set('isPresent', this.ispresent);

      let data = `${this.VUE_APP_API_URL}/api/trainee/attendance-statusprint`;
      axios
        .post(data, formData)
        .then(response => {
          //window.open(data, '_blank');
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it         
        })
    },
    async getAttendanceStatus() {
      if (this.ispresent == "present") {
        this.ispresent = 1;
      }
      if (this.ispresent == "absent") {
        this.ispresent = 0;
      }
      this.load = true;
      await ApiService.get(
        "trainee/attendance-status?traineeId=" +
        this.id +
        "&isPresent=" +
        this.ispresent
      )
        .then((response) => {

          this.tableData = response.data.data.attendance_info;
          this.trainee_info = response.data.data.trainee_info;
          this.datatable = true;
          this.load = false;
        })
        .then((response) => {
          console.log(response);
        });
    },
  },
});
